import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import TarifasInternet from "./TarifasInternet";
import PropTypes from "prop-types";
import ChatBot from "react-simple-chatbot";
import ChatBotIcon from "../../stylesheets/assets/icons/robot.svg";
import BubbleTail from "../../stylesheets/assets/icons/initialMessageBubbleTail.svg";
import BotAvatar from "../../stylesheets/assets/icons/bot-avatar.webp";
class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      contratado: "",
      servicios: "",
      tarifasInternet: [],
      loading: false
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, contratado, servicios, email } = steps;

    this.setState({ name, contratado, servicios, email });
  }

  render() {
    const { name, contratado, servicios, email } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <h3>Resumen</h3>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>Nombre</td>
              <td>{name.value}</td>
            </tr>
            <tr>
              <td>Servicios</td>
              <td>{servicios.value}</td>
            </tr>
            <tr>
              <td>Contratado</td>
              <td>{contratado.value}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{email.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

class ChatBotPage extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true, isMsgOn: true };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleClickMsg = this.handleClickMsg.bind(this);
  }
  handleClick() {
    this.setState(function(prevState) {
      return { isToggleOn: !prevState.isToggleOn };
    });
  }
  handleClickMsg() {
    this.setState({ isMsgOn: false });
  }
  handleEnd({ steps, values }) {
    console.log(steps);
    console.log(values);
    alert(`Chat handleEnd callback! Number: ${values[0]}`);
  }

  componentDidMount() {
    this.handleEnd = this.handleEnd.bind(this);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isToggleOn ? (
          <div className="widgetBot">
            {this.state.isMsgOn ? (
              <div className="p-top-8 p-bottom-4 InitialMessageBubble__Wrapper-dvdbPa kVzEiH">
                <div className="initial-message-bubble">
                  <button
                    onClick={this.handleClickMsg}
                    type="button"
                    className="uiButton private-button private-button--xs private-button--unstyled initial-message-close-button private-button--non-responsive private-button--non-link"
                  >
                    <svg
                      height={24}
                      viewBox="0 0 24 24"
                      width={24}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </button>
                  <div
                    className="initial-message-avatar justify-center"
                    style={{ top: "-24px" }}
                  >
                    <div className="justify-center" style={{ height: "48px" }}>
                      <div className="chat-head">
                        <div
                          className="chat-head-avatar AvatarWrapper-hhMvHp hSVFBx"
                          style={{ width: "48px" }}
                        >
                          <div
                            className="AvatarContentHolder__ContentHolder-iWmAjV hmVoHv"
                            type="contact"
                          >
                            <svg
                              viewBox="0 0 100 100"
                              xmlns="http://www.w3.org/2000/svg"
                              className="SVGWrapper-fuxyRA biIhpP"
                              style={{ backgroundImage: `url(${BotAvatar})` }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="initial-message-text m-top-1 m-bottom-0">
                    ¿Tienes alguna pregunta?
                  </p>
                  <div className="InitialMessageBubble__BubbleTailWrapper-gFKgtC hweVaR">
                    <img
                      alt="avatar bot"
                      className="private-image img-responsive private-image--responsive"
                      src={BubbleTail}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}

            <button onClick={this.handleClick} className="godhbL">
              <svg
                height={24}
                viewBox="0 0 24 24"
                width={24}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </button>
          </div>
        ) : (
          <ChatBot
            handleEnd={this.handleEnd}
            speechSynthesis={{ enable: true, lang: "es" }}
            className="chatbot"
            headerComponent={
              <div className="rsc-header sc-gqjmRU glfuN">
                <h2 className="rsc-header-title sc-VigVT lifvqk">
                  Chat Bot Nolu
                </h2>
                <button onClick={this.handleClick} className="closeButtonChat">
                  <svg
                    height={24}
                    viewBox="0 0 24 24"
                    width={24}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </button>
              </div>
            }
            bubbleOptionStyle={{ background: "#17AFBF" }}
            bubbleStyle={{ background: "#FFF", color: "#555" }}
            style={{ position: "absolute", zIndex: 999 }}
            headerTitle="Chat Bot Nolu"
            placeholder="Escribe tu mensaje"
            botAvatar={BotAvatar}
            steps={[
              {
                id: "0",
                message: "Hola! Soy Poniente",
                trigger: "1"
              },
              {
                id: "1",
                message: "¿Como te llamas?",
                trigger: "name"
              },
              {
                id: "name",
                user: true,
                trigger: "pedir-email"
              },
              {
                id: "pedir-email",
                message:
                  "¡Gracias, {previousValue}! ¿Puedes darme tu dirección de correo electrónico? Por si me desconectan... ",
                trigger: "email"
              },
              {
                id: "email",
                user: true,
                trigger: "ofrecer-servicios"
              },
              {
                id: "ofrecer-servicios",
                message: "¿En que servicio estás interesado",
                trigger: "servicios"
              },
              {
                id: "servicios",
                options: [
                  { value: "internet", label: "Internet", trigger: "5" },
                  {
                    value: "llamadas",
                    label: "Llamadas",
                    trigger: "no-tiene-internet"
                  },
                  {
                    value: "Instalaciones",
                    label: "Instalaciones",
                    trigger: "5"
                  }
                ]
              },
              {
                id: "5",
                message: "¿Tienes contradado el servicio?",
                trigger: "contratado"
              },
              {
                id: "contratado",
                trigger: "7",
                options: [
                  { value: "si", label: "Si", trigger: "7" },
                  { value: "no", label: "No", trigger: "no-tiene-internet" }
                ]
              },
              {
                id: "7",
                message: "¿Estos datos son correctos?",
                trigger: "review"
              },
              {
                id: "review",
                component: <Review />,
                asMessage: true,
                trigger: "update"
              },
              {
                id: "no-tiene-internet",
                message: "¿En que estás interesado?",
                trigger: "no-tiene-internet-eleccion"
              },
              {
                id: "no-tiene-internet-eleccion",
                options: [
                  {
                    value: "precios",
                    label: "Precios",
                    trigger: "precios-internet"
                  }
                ]
              },
              {
                id: "precios-internet",
                message: "Estas son las tarifas que tenemos actualmente",
                trigger: "listado-precios-internet"
              },
              {
                id: "listado-precios-internet",
                component: <TarifasInternet />,
                asMessage: true,
                trigger: "update"
              },
              {
                id: "update",
                message: "Quieres actualizar algo?",
                trigger: "update-question"
              },
              {
                id: "update-question",
                options: [
                  { value: "si", label: "Si", trigger: "update-yes" },
                  { value: "no", label: "No", trigger: "end-message" }
                ]
              },
              {
                id: "update-yes",
                message: "What field would you like to update?",
                trigger: "update-fields"
              },
              {
                id: "update-fields",
                options: [
                  { value: "name", label: "Name", trigger: "update-name" },
                  {
                    value: "gender",
                    label: "Gender",
                    trigger: "update-gender"
                  },
                  { value: "age", label: "Age", trigger: "update-age" }
                ]
              },
              {
                id: "update-name",
                update: "name",
                trigger: "7"
              },
              {
                id: "update-gender",
                update: "gender",
                trigger: "7"
              },
              {
                id: "update-age",
                update: "age",
                trigger: "7"
              },
              {
                id: "end-message",
                message:
                  "Espero haberte ayudado, si no ha sido así, mejor que hables con un humano 🤗!",
                end: true
              }
            ]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withFirebase(ChatBotPage);
