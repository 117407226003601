import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import ComunicadoList from "./ComunicadoList";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

class Comunicados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      text: "",
      loading: false,
      comunicados: [],
      limit: 2,
      editorState: EditorState.createEmpty()
    };
  }

  componentDidMount() {
    this.onListenForComunicados();
  }

  onListenForComunicados = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .comunicados()
      .orderBy("createdAt", "asc")
      .limit(this.state.limit)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let comunicados = [];
          snapshot.forEach(doc =>
            comunicados.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            comunicados: comunicados.reverse(),
            loading: false
          });
        } else {
          this.setState({ comunicados: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

  onCreateComunicado = (event, authUser) => {
    if (!this.state.title || this.state.title.trim().length === 0) {
      this.setState({ titleError: "El campo título es obligatorio" });
      return;
    }
    if (!this.state.text || this.state.text.trim().length === 0) {
      this.setState({ textError: "El campo descripción es obligatorio" });
      return;
    }
    console.log(this.state);
    this.props.firebase.comunicados().add({
      title: this.state.title,
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp()
    });
    this.setState({ titleError: "", textError: "" });
    this.setState({
      text: "",
      title: "",
      editorState: EditorState.createEmpty()
    });

    event.preventDefault();
  };

  onEditComunicado = (comunicado, title, text) => {
    const { uid, ...comunicadoSnapshot } = comunicado;
    console.log(uid);
    this.props.firebase.comunicado(comunicado.uid).update({
      ...comunicadoSnapshot,
      title,
      text,
      editedAt: this.props.firebase.fieldValue.serverTimestamp()
    });
  };

  onRemoveComunicado = uid => {
    this.props.firebase.comunicado(uid).delete();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForComunicados
    );
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };

  render() {
    const { title, text, comunicados, loading, editorState } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            {loading && <div>Loading ...</div>}

            {comunicados && (
              <ComunicadoList
                authUser={authUser}
                comunicados={comunicados}
                onEditComunicado={this.onEditComunicado}
                onRemoveComunicado={this.onRemoveComunicado}
              />
            )}

            {!comunicados && <div>No hay mensajes ...</div>}

            {!loading && comunicados && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.onNextPage}
              >
                Mostrar más
              </button>
            )}
            <hr />
            <form onSubmit={event => this.onCreateComunicado(event, authUser)}>
              <div className="form-group">
                <div className="input-group input-group-alternative mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="ni ni-hat-3" />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Título"
                    type="text"
                    value={title}
                    onChange={this.onChangeTitle}
                  />
                </div>
                {this.state.titleError}
              </div>
              <div className="form-group">
                <div className="input-group input-group-alternative mb-3">
                  <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      options: ["inline", "link", "emoji"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                        bold: { className: "bordered-option-classname" },
                        italic: { className: "bordered-option-classname" },
                        underline: { className: "bordered-option-classname" }
                      }
                    }}
                  />
                  <textarea
                    name="description"
                    disabled
                    style={{ display: "none" }}
                    value={draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    )}
                  />
                  {this.state.textError}
                </div>
              </div>
              {/* <input type="text" value={title} onChange={this.onChangeTitle} /> */}

              <button
                type="submit"
                className="btn btn-info btn-icon mb-3 mb-sm-0"
              >
                Enviar{" "}
              </button>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Comunicados);
