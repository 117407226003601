import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

const SignUpPage = () => (
  <div>
    <div
      style={{
        minHeight: "400px",
        background: "#000",
        paddingTop: "200px"
      }}
    >
      <div className="container">
        <h1>SignUp</h1>
      </div>
    </div>

    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            roles
          },
          { merge: true }
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <div className="container">
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                placeholder="Nombre"
                type="text"
                name="username"
                value={username}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Dirección de email"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Contraseña"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirmar contraseña"
              />
            </div>
          </div>

          <label>
            Admin:
            <input
              name="isAdmin"
              type="checkbox"
              checked={isAdmin}
              onChange={this.onChangeCheckbox}
            />
          </label>
          <div className="col-12">
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckRegister"
                type="checkbox"
              />
              <label className="custom-control-label" for="customCheckRegister">
                <span>ADMINISTRADOR</span>
              </label>
            </div>
          </div>
          <button
            disabled={isInvalid}
            type="submit"
            className="btn btn-primary my-4"
          >
            Crear usuario
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

const SignUpLink = () => (
  <div className="col-6 text-right">
    <Link to={ROUTES.SIGN_UP}>
      <small>Crear nueva cuenta</small>
    </Link>
  </div>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(SignUpPage);

export { SignUpForm, SignUpLink };
