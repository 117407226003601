import React from "react";
import { Link } from "react-router-dom";

import { AuthUserContext } from "../Session";
import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { Header } from "../../common/Header";

import { closeNavBar } from "../../lib/tools";

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Header>
          <NavigationAuth authUser={authUser} />
        </Header>
      ) : (
        <Header>
          <NavigationNonAuth />
        </Header>
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <ul className="navbar-nav align-items-lg-center ml-lg-auto">
    <li className="nav-item">
      <Link
        to={ROUTES.LANDING}
        className="nav-link"
        data-toggle="collapse"
        onClick={closeNavBar()}
      >
        Inicio
      </Link>
    </li>
    <li className="nav-item">
      <Link to={ROUTES.HOME} className="nav-link" data-toggle="collapse">
        Profile
      </Link>
    </li>
    <li className="nav-item">
      <Link to={ROUTES.ACCOUNT} className="nav-link" data-toggle="collapse">
        Mi Cuenta
      </Link>
    </li>
    {!!authUser.roles[ROLES.ADMIN] && (
      <li className="nav-item">
        <Link to={ROUTES.ADMIN} className="nav-link" data-toggle="collapse">
          Administración
        </Link>
      </li>
    )}
    <li className="nav-item">
      <SignOutButton />
    </li>
  </ul>
);

const NavigationNonAuth = () => (
  <ul className="navbar-nav align-items-lg-center ml-lg-auto">
    <li className="nav-item">
      <Link to={ROUTES.LANDING} className="nav-link" data-toggle="collapse">
        Inicio
      </Link>
    </li>
    <li className="nav-item">
      <Link to={ROUTES.SIGN_IN} className="nav-link" data-toggle="collapse">
        Iniciar Sesión
      </Link>
    </li>
  </ul>
);

export default Navigation;
