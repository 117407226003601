import React from "react";

import ComunicadoItem from "./ComunicadoItem";

const ComunicadoList = ({
  authUser,
  comunicados,
  onEditComunicado,
  onRemoveComunicado
}) => (
  <div>
    <h2>Comunicados</h2>
    <ul className="comunicados">
      {comunicados.map(comunicado => (
        <ComunicadoItem
          authUser={authUser}
          key={comunicado.uid}
          comunicado={comunicado}
          onEditComunicado={onEditComunicado}
          onRemoveComunicado={onRemoveComunicado}
        />
      ))}
    </ul>
  </div>
);

export default ComunicadoList;
