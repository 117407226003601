import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import logo from "../../stylesheets/assets/logo.svg";
class Header extends Component {
  state = {
    currentScrollHeight: null
  };
  // componentDidMount() {
  //   window.onscroll = () => {
  //     const newScrollHeight = Math.ceil(window.scrollY / 50) * 50;
  //     if (this.state.currentScrollHeight != newScrollHeight) {
  //       this.setState({ currentScrollHeight: newScrollHeight });
  //     }
  //   };
  // }

  componentDidMount() {
    const nav = document.querySelector("nav");
    const navTop = nav.offsetTop;

    console.log(nav.offsetHeight);
    function handleScroll() {
      if (window.pageYOffset > 1) {
        nav.classList.add("fixed-nav");
        document.body.style.paddingTop = nav.offsetTop + "px";
      } else {
        nav.classList.remove("fixed-nav");
        document.body.style.paddingTop = 0;
      }
    }

    window.addEventListener("scroll", handleScroll);
  }

  // componentDidMount() {
  //   const nav = document.querySelector('nav');
  //   const navTop = nav.offsetTop;

  //   function handleScroll() {
  //     if (window.scrollY > 200) {
  //       nav.classList.add('fixed-nav');
  //       document.body.style.paddingTop = nav.offsetHeight + 'px';
  //     } else {
  //       nav.classList.remove('fixed-nav');
  //       document.body.style.paddingTop = 0;
  //     }
  //   }

  //   window.addEventListener('scroll', handleScroll);
  // }

  handleClick() {
    const wrapper = document.getElementById("navbar_global");
    wrapper.classList.toggle("show");
  }
  render() {
    // if (window.location.pathname === '/signin') return null;
    const opacity = Math.min(100 / this.state.currentScrollHeight, 1);
    return (
      <div style={{ opacity }}>
        <header className="header-global">
          <nav className="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom">
            <div className="container-fluid">
              <Link to="#" className="navbar-brand mr-lg-5">
                <img src={logo} alt="logo Nolu Networks" />
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar_global"
                aria-controls="navbar_global"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => this.handleClick()}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="navbar-collapse collapse" id="navbar_global">
                <ul className="navbar-nav navbar-nav-hover align-items-lg-center" />
                {this.props.children}
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export { Header };
