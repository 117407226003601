export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = "/admin";
export const ADMIN_DETAILS = "/admin/:id";
export const COMUNIDADOS = "/comunicado";
export const COMUNICADO_DETAILS = "/comunicado/:id";
