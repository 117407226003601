import React from "react";

const TarifasMovilesList = ({ tarifasMoviles }) => (
  <React.Fragment>
    {tarifasMoviles.map((tarifaMovil, i) => (
      <div
        className="col-md-6 col-lg-4 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="0"
        key={i}
      >
        <article className="bordered bg-white rising p-2 p-lg-4 h-100">
          {" "}
          <span className="eyebrow mb-2 text-gray">Nolu Networks</span>
          <h3 className="text-black boxing">{tarifaMovil.minutos}</h3>
          <h3 className="text-black boxing">
            {tarifaMovil.datos} {tarifaMovil.sms}
          </h3>
          <button
            // onClick={this.openModal}
            //   onClick={() => this.onClick()}
            className="text-uppercase btn btn-sm btn-rounded btn-outline-primary mt-2"
          >
            Contratar por {tarifaMovil.precio}
          </button>
        </article>
      </div>
    ))}
  </React.Fragment>
);

export default TarifasMovilesList;
