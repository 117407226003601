import React, { Component } from "react";

import { withFirebase } from "../Firebase";

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      loadingFiles: false,
      files: null,
      limit: 5,
      ...props.location.state
    };
  }

  componentDidMount() {
    this.onListenForPictures();
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          user: snapshot.data(),
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  onListenForPictures = user => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .files()
      .where("userId", "==", this.props.match.params.id)
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach(doc => files.push({ ...doc.data(), uid: doc.id }));

          this.setState({
            files: files.reverse(),
            loadingFiles: false
          });
        } else {
          this.setState({ files: null, loadingFiles: false });
        }
      });
  };

  render() {
    const { user, loading, loadingFiles, files } = this.state;
    console.log(loading);
    return (
      <div className="container">
        <h2>User ({this.props.match.params.id})</h2>
        {/* {loading && <div>Loading ...</div>} */}

        {user && (
          <div>
            <span>
              <strong>ID:</strong> {user.uid}
            </span>
            <span>
              <strong>E-Mail:</strong> {user.email}
            </span>
            <span>
              <strong>Username:</strong> {user.username}
            </span>
            <span>
              <button
                type="button"
                className="btn btn-primary my-4"
                onClick={this.onSendPasswordResetEmail}
              >
                Enviar reseteo de contraseña
              </button>
            </span>
          </div>
        )}
        <hr />
        {loadingFiles && <div>Loading files...</div>}

        {files && (
          <div className="">
            <div className="row">
              {files.map((file, i) => (
                <div className="col-lg-4" key={i}>
                  <img src={file.file} alt="" className="w100" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(UserItem);
