import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import FontAwesome from "react-fontawesome";

class ComunicadoItem extends Component {
  constructor(props) {
    super(props);
    const blocksFromHtml = htmlToDraft(this.props.comunicado.text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    this.state = {
      editMode: false,
      editText: this.props.comunicado.text,
      editorState: EditorState.createWithContent(contentState)
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editTitle: this.props.comunicado.title,
      editText: this.props.comunicado.text
    }));
  };

  onChangeEditTitle = event => {
    this.setState({ editTitle: event.target.value });
  };
  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditComunicado(
      this.props.comunicado,
      this.state.editTitle,
      this.state.editText
    );

    this.setState({ editMode: false });
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      editText: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };
  render() {
    const { authUser, comunicado, onRemoveComunicado } = this.props;
    const { editMode, editText, editTitle, editorState } = this.state;
    // console.log(comunicado);
    return (
      <li>
        {editMode ? (
          <div>
            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Título"
                  type="text"
                  value={editTitle}
                  onChange={this.onChangeEditTitle}
                />
              </div>
            </div>

            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: ["inline", "link", "emoji"],
                inline: {
                  options: ["bold", "italic", "underline"],
                  bold: { className: "bordered-option-classname" },
                  italic: { className: "bordered-option-classname" },
                  underline: { className: "bordered-option-classname" }
                }
              }}
            />
            <textarea
              name="description"
              disabled
              style={{ display: "none" }}
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            />
          </div>
        ) : (
          <span>
            <div>
              <Link to={"/comunicado/" + comunicado.uid}>
                {comunicado.title}
              </Link>{" "}
              {comunicado.editedAt && (
                <span className="badge badge-info">Editado</span>
              )}
              <br />
              <span className="fechaComunicado">
                {comunicado.createdAt &&
                  moment(comunicado.createdAt.toDate()).format("LL")}
              </span>
            </div>
          </span>
        )}
        {authUser.uid === comunicado.userId && (
          <span>
            {editMode ? (
              <span>
                <button
                  className="btn btn-success"
                  onClick={this.onSaveEditText}
                >
                  Guardar
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={this.onToggleEditMode}
                >
                  Cancelar
                </button>
              </span>
            ) : (
              <button
                className="btn btn-info miniIcons"
                onClick={this.onToggleEditMode}
              >
                <FontAwesome name="edit" />
              </button>
            )}

            {!editMode && (
              <button
                type="button"
                className="btn btn-danger miniIcons"
                onClick={() => {
                  onRemoveComunicado(comunicado.uid);
                  console.log(comunicado);
                }}
              >
                <FontAwesome name="trash" />
              </button>
            )}
          </span>
        )}
      </li>
    );
  }
}

export default ComunicadoItem;
