import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import axios from "axios";
import Accordion from "../../common/Accordion/Accordion";
import AccordionItem from "../../common/Accordion/AccordionItem";
import Particles from "react-particles-js";
import { HashLink } from "react-router-hash-link";
import ReactDOM from "react-dom";
import Modal from "react-modal";
// import { getFileExtension } from "../../lib/tools";
import AOS from "aos";
import "aos/dist/aos.css";
import homeBackground from "../../stylesheets/assets/background/home.jpg";
import servidoresBackground from "../../stylesheets/assets/background/servidores.jpg";
import { isValid, isInt } from "../../lib/tools";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "moment/locale/es";
import "react-dates/lib/css/_datepicker.css";
import TarifasMovilesList from "./TarifasMovilesList";
import TarifasInternetList from "./TarifasInternetList";
moment.locale("es-ES");

var FontAwesome = require("react-fontawesome");
var homeStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  overflow: "hidden",
  zIndex: -1,
  width: "100%",
  backgroundImage: `url(${homeBackground})`
};
const servidoresStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  overflow: "hidden",
  zIndex: -1,
  width: "100%",
  backgroundImage: `url(${servidoresBackground})`
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

window.addEventListener(
  "resize",
  function() {
    // use ".addEventListener", not ".onresize"
    checkOnResize();
  },
  true
);

function checkOnResize() {
  if (window.innerWidth > 1100) {
    console.log("desktop mode");
  } else {
    console.log("mobile mode");
  }
}
Modal.setAppElement(document.getElementById("root"));
class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openEquipo: true,
      modalIsOpen: false,
      loading: false,
      tarifasMoviles: [],
      loadingFaq: false,
      faq: [],
      tarifasInternet: [],
      loadingTarifasInternet: false
    };
    this.toggleExpandedTicket = this.toggleExpandedTicket.bind(this);
    this.onClick = this.onClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.onListenFortarifasMoviles();
    this.onListenFortarifasInternet();
    this.onListenForFaq();
  }

  onListenFortarifasMoviles = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .tarifasMoviles()
      .orderBy("orden", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let tarifasMoviles = [];
          snapshot.forEach(doc =>
            tarifasMoviles.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            tarifasMoviles: tarifasMoviles.reverse(),
            loading: false
          });
        } else {
          this.setState({ tarifasMoviles: null, loading: false });
        }
      });
  };
  onListenFortarifasInternet = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .tarifasInternet()
      .orderBy("orden", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let tarifasInternet = [];
          snapshot.forEach(doc =>
            tarifasInternet.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            tarifasInternet: tarifasInternet.reverse(),
            loading: false
          });
        } else {
          this.setState({
            tarifasInternet: null,
            loadingTarifasInternet: false
          });
        }
      });
  };
  onListenForFaq = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .preguntasFrecuentes()
      .orderBy("orden", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let faq = [];
          snapshot.forEach(doc => faq.push({ ...doc.data(), uid: doc.id }));

          this.setState({
            faq: faq.reverse(),
            loadingFaq: false
          });
        } else {
          this.setState({
            faq: null,
            loadingFaq: false
          });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentWillMount() {
    AOS.init();
  }
  toggleExpandedTicket() {
    this.setState({ openEquipo: !this.state.openEquipo });
  }
  onClick = event => {
    console.log(event);
    this.setState({ producto: event, modalIsOpen: true });
  };
  // modal
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const {
      loading,
      tarifasMoviles,
      loadingTarifasInternet,
      tarifasInternet,
      loadingFaq,
      faq
    } = this.state;
    return (
      <div>
        <main>
          <div className="position-relative">
            <section className="section section-lg section-shaped pb-250 full-height">
              <div className="row">
                <div className="container py-lg-md d-flex">
                  <div className="col">
                    <div className="row">
                      <div className="col-lg-6">
                        <h1 className="display-3  text-white">
                          Internet, Móvil y Llamadas
                          <span>en Zahara de los Atunes</span>
                        </h1>
                        <p className="lead  text-white">
                          Contratos de internet temporales y permanentes.
                        </p>
                        <div className="btn-wrapper">
                          <HashLink
                            to="#tarifas-internet"
                            className="btn btn-info btn-icon mb-3 mb-sm-0"
                          >
                            <span className="btn-inner--icon">
                              <FontAwesome name="wifi" />
                            </span>
                            <span className="btn-inner--text">
                              Tarifas de Internet
                            </span>
                          </HashLink>
                          <HashLink
                            to="#tarifas-moviles"
                            className="btn btn-white btn-icon mb-3 mb-sm-0"
                          >
                            <span className="btn-inner--icon">
                              <FontAwesome name="mobile" />
                            </span>
                            <span className="btn-inner--text">
                              Tarifas de Móviles
                            </span>
                          </HashLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={homeStyle}>
                  <div
                    className="swiper-footer aos-init"
                    data-aos="f-out"
                    data-aos-anchor-placement="top-center"
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col text-center">
                          <div className="mouse" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Particles
                    params={{
                      particles: {
                        number: {
                          value: 50,
                          density: {
                            enable: true,
                            value_area: 2000
                          }
                        },
                        color: {
                          value: "#ffffff"
                        },
                        shape: {
                          type: "circle",
                          stroke: {
                            width: 0,
                            color: "#000000"
                          },
                          polygon: {
                            nb_sides: 5
                          }
                        },
                        opacity: {
                          value: 0.7,
                          random: false,
                          anim: {
                            enable: false,
                            speed: 1,
                            opacity_min: 0.3,
                            sync: false
                          }
                        },
                        size: {
                          value: 1,
                          random: true,
                          anim: {
                            enable: false,
                            speed: 40,
                            size_min: 0.1,
                            sync: false
                          }
                        },
                        line_linked: {
                          enable: true,
                          distance: 150,
                          color: "#ffffff",
                          opacity: 0.4,
                          width: 1
                        },
                        move: {
                          enable: true,
                          speed: 2,
                          direction: "none",
                          random: false,
                          straight: false,
                          out_mode: "out",
                          bounce: false,
                          attract: {
                            enable: false,
                            rotateX: 1200,
                            rotateY: 1200
                          }
                        }
                      },
                      interactivity: {
                        detect_on: "canvas",
                        events: {
                          onhover: {
                            enable: true,
                            mode: "grab"
                          },
                          onclick: {
                            enable: true,
                            mode: "push"
                          },
                          resize: true
                        },
                        modes: {
                          grab: {
                            distance: 150,
                            line_linked: {
                              opacity: 1
                            }
                          },
                          bubble: {
                            distance: 400,
                            size: 40,
                            duration: 2,
                            opacity: 8,
                            speed: 3
                          },
                          repulse: {
                            distance: 200,
                            duration: 0.4
                          },
                          push: {
                            particles_nb: 4
                          },
                          remove: {
                            particles_nb: 2
                          }
                        }
                      },
                      retina_detect: true
                    }}
                  />
                </div>
              </div>
            </section>

            <section
              className="section section-lg inverted"
              id="sobre-nosotros"
            >
              <div className="container p-0 mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center align-items-center">
                    <h2 className="eyebrow d-block mb-1">SOBRE NOSOTROS</h2>
                    <p className="lead text-black">
                      Nolu Networks es una empresa tecnológica ubicada en Zahara
                      de los Atunes. Ofreciendo sercivios de Internet, Llamadas
                      y Domótica. La empresa actualmente da cobertura a Zahara
                      de los Atunes, Atlanterra, La Zarzuela, El Almarchal,
                      Bolonia y Tahivilla.
                    </p>
                    <p className="lead text-black">
                      Nuestro compromiso es la seguridad y calidad del servicio
                      y el respeto por el medio ambiente. Ofreciendo una
                      conexión a internet segura y estable a un precio muy
                      económico con el mínimo impacto ambiental posible.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="section section-lg" id="tarifas-internet">
              <div className="container p-0 mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center align-items-center">
                    <h2 className="eyebrow d-block mb-1">
                      Tarifas de Internet
                    </h2>
                    <p className="lead text-black">
                      Desde internet temporal, hasta 100MB de Fibra Óptica!.
                      Busca la que más te convenga.
                    </p>
                  </div>
                </div>

                <div className="row gutter-0 no-gutters">
                  {loadingTarifasInternet && <div>Loading ...</div>}

                  {tarifasInternet && (
                    <TarifasInternetList tarifasInternet={tarifasInternet} />
                  )}
                </div>
              </div>
            </section>

            <section className="section section-lg" id="tarifas-moviles">
              <div className="container p-0 mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center align-items-center">
                    <h2 className="eyebrow d-block mb-1">Tarifas Móviles</h2>
                    <p className="lead text-black">
                      Te ofrecemos distintos paquetes para que puedas escoger el
                      que mejor se adapte a tus necesides.
                    </p>
                  </div>
                </div>

                <div className="row gutter-0 no-gutters">
                  {loading && <div>Loading ...</div>}

                  {tarifasMoviles && (
                    <TarifasMovilesList tarifasMoviles={tarifasMoviles} />
                  )}
                </div>
              </div>
            </section>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h2 ref={subtitle => (this.subtitle = subtitle)}>Hello</h2>
              <button onClick={this.closeModal}>close</button>
              <div>I am a modal</div>
              <Contratar producto={this.state.producto} />
            </Modal>
            <section className="section section-lg section-shaped pb-250 full-height">
              <div className="row">
                <div className="container py-lg-md d-flex">
                  <div className="col">
                    <div className="row">
                      <div className="col-lg-6">
                        <h1 className="display-3  text-white">
                          Expertos en Domótica
                          <span>
                            Convertimos tu hogar en un hogar inteligente
                          </span>
                        </h1>
                        <p className="lead  text-white">
                          Ahorre tiempo todos los días y controle todo su hogar
                          desde su dispositivo móvil.
                        </p>
                        <p className="lead  text-white">
                          Programa el aire acondicionado para cuando vuelvas a
                          casa esté fresquita, controla las luces de la casa
                          para ahorrar energía...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={servidoresStyle} />
              </div>
            </section>

            <section className="section section-lg" id="preguntas-frecuentes">
              <div className="container container p-0 mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center align-items-center">
                    <h2 className="eyebrow d-block mb-1">
                      Preguntas Frecuentes
                    </h2>
                    <p className="lead text-black">
                      Respondemos a las preguntas más solicitadas por nuestros
                      clientes
                    </p>
                  </div>
                </div>
                {loadingFaq && <div>Loading ...</div>}

                {faq && (
                  <div {...{ className: "wrapper" }}>
                    <ul {...{ className: "accordion-list" }}>
                      {faq.map((data, key) => {
                        return (
                          <li {...{ className: "accordion-list__item", key }}>
                            <AccordionItem {...data} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </section>

            <section className="section section-lg" id="preguntas-frecuentes">
              <div className="container container p-0 mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center align-items-center">
                    <h2 className="eyebrow d-block mb-1">
                      Contratar nuestros servicios
                    </h2>
                    <p className="lead text-black">
                      Rellena el formulario para gestionar tu alta en internet.
                    </p>
                  </div>
                </div>
                <Contactar />
              </div>
            </section>

            {/* <Mailing /> */}
          </div>
        </main>
      </div>
    );
  }
}

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

class Contratar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      apellidos: "",
      email: "",
      telefono: "",
      dni: "",
      localidad: "",
      password: "",
      startDate: "",
      endDate: "",
      focusedInput: null,
      errors: {
        nombre: "",
        apellidos: "",
        email: "",
        telefono: "",
        dni: "",
        localidad: "",
        password: "",
        startDate: "",
        endDate: ""
      }
    };
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "nombre":
        errors.nombre =
          value.length < 5 ? "Nombre debe contener al menos 5 caracteres!" : "";
        break;
      case "apellidos":
        errors.apellidos =
          value.length < 5
            ? "Apellidos debe contener al menos 5 caracteres!"
            : "";
        break;
      case "dni":
        // errors.dni = value.length < 9 ? "DNI debe contener al menos 5 caracteres!" : "";
        errors.dni = isValid(value) ? "" : "Email no es válido!";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email no es válido!";
        break;
      case "telefono":
        errors.telefono =
          !isInt(value) || value.length < 9
            ? "Teléfono debe contener al menos 9 caracteres!"
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      console.info("Valid Form");
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="row justify-content-center" id="modalContratar">
        <div
          className="col-md-12 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="0"
        >
          <form id="feedback-form" onSubmit={this.handleSubmit} noValidate>
            <h2>Alta Nueva</h2>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Nombre"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.name}
                  name="nombre"
                  noValidate
                />
              </div>
              {errors.nombre.length > 0 && (
                <span className="error">{errors.nombre}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Apellidos"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.apellidos}
                  name="apellidos"
                  noValidate
                />
              </div>
              {errors.apellidos.length > 0 && (
                <span className="error">{errors.apellidos}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Correo electrónico"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  name="email"
                  noValidate
                />
              </div>
              {errors.email.length > 0 && (
                <span className="error">{errors.email}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Teléfono"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.telefono}
                  name="telefono"
                  noValidate
                />
              </div>
              {errors.telefono.length > 0 && (
                <span className="error">{errors.telefono}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="DNI"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.dni}
                  name="dni"
                  noValidate
                />
              </div>
              {errors.dni.length > 0 && (
                <span className="error">{errors.dni}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Localidad"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.localidad}
                  name="localidad"
                  noValidate
                />
              </div>
              {errors.localidad.length > 0 && (
                <span className="error">{errors.localidad}</span>
              )}
            </div>

            <div className="form-group">
              <DateRangePicker
                customArrowIcon={
                  <FontAwesome name="arrow-right" color="green" />
                }
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="startDate" // PropTypes.string.isRequired,
                startDatePlaceholderText="Fecha Inicio"
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="endDate" // PropTypes.string.isRequired,
                endDatePlaceholderText="Fecha Fin"
                onDatesChange={({ startDate, endDate }) =>
                  this.setState({ startDate, endDate })
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                monthFormat="MMMM YYYY"
              />
            </div>

            <div className="info">
              <small>Debes rellenar todos los campos.</small>
            </div>
            <div className="submit">
              {/* <button className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0">Create</button> */}
              <button
                type="submit"
                className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0"
              >
                ENVIAR
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

class Contactar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      apellidos: "",
      email: "",
      telefono: "",
      message: "",
      errors: {
        nombre: "",
        apellidos: "",
        email: ""
      }
    };
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "nombre":
        errors.nombre =
          value.length < 5 ? "Nombre debe contener al menos 5 caracteres!" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email no es válido!";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      console.info("Valid Form");
      // /api/send-mail
      let data = {
        name: this.state.nombre,
        email: this.state.email,
        phone: this.state.telefono,
        message: this.state.message
      };

      axios
        .post("https://nolu.es/api/send-mail", data)
        .then(res => {
          this.setState({
            buttonText: "Enviar otro mensaje",
            enviadoOk:
              "Su mensaje fué enviado con éxito. En breve contactaremos con usted."
          });
          this.setState({ sent: true }, this.resetForm());
        })
        .catch(() => {
          console.log("Message not sent");
        });
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="row justify-content-center">
        <div
          className="col-md-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="0"
        >
          <form id="feedback-form" onSubmit={this.handleSubmit} noValidate>
            <h2>Contactar</h2>
            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Nombre"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.name}
                  name="nombre"
                  noValidate
                />
              </div>
              {errors.nombre.length > 0 && (
                <span className="error">{errors.nombre}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Correo electrónico"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  name="email"
                  noValidate
                />
              </div>
              {errors.email.length > 0 && (
                <span className="error">{errors.email}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Teléfono"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.telefono}
                  name="telefono"
                  noValidate
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <textarea
                  className="form-control"
                  placeholder="Mensaje"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.message}
                  name="message"
                  noValidate
                />
              </div>
            </div>

            <div className="info">
              <small>Contactaremos contigo lo antes posible.</small>
            </div>
            <div className="submit">
              {/* <button className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0">Create</button> */}
              <button
                type="submit"
                className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0"
              >
                ENVIAR
              </button>
            </div>
            <p>{this.state.enviadoOk}</p>
          </form>
        </div>
      </div>
    );
  }
}
export default withFirebase(Landing);
