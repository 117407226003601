import React, { Component } from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";
import { withFirebase } from "../Firebase";
import { PasswordForgetForm } from "../PasswordForgetAccount";
import PasswordChangeForm from "../PasswordChange";
import Particles from "react-particles-js";

const SIGN_IN_METHODS = [
  {
    id: "password",
    provider: null
  },
  {
    id: "google.com",
    provider: "googleProvider"
  },
  {
    id: "facebook.com",
    provider: "facebookProvider"
  },
  {
    id: "twitter.com",
    provider: "twitterProvider"
  }
];

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <main>
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <div>
                <Particles
                  params={{
                    particles: {
                      number: {
                        value: 50,
                        density: {
                          enable: true,
                          value_area: 2000
                        }
                      },
                      color: {
                        value: "#ffffff"
                      },
                      shape: {
                        type: "circle",
                        stroke: {
                          width: 0,
                          color: "#000000"
                        },
                        polygon: {
                          nb_sides: 5
                        }
                      },
                      opacity: {
                        value: 0.7,
                        random: false,
                        anim: {
                          enable: false,
                          speed: 1,
                          opacity_min: 0.3,
                          sync: false
                        }
                      },
                      size: {
                        value: 1,
                        random: true,
                        anim: {
                          enable: false,
                          speed: 40,
                          size_min: 0.1,
                          sync: false
                        }
                      },
                      line_linked: {
                        enable: true,
                        distance: 150,
                        color: "#ffffff",
                        opacity: 0.4,
                        width: 1
                      },
                      move: {
                        enable: true,
                        speed: 2,
                        direction: "none",
                        random: false,
                        straight: false,
                        out_mode: "out",
                        bounce: false,
                        attract: {
                          enable: false,
                          rotateX: 1200,
                          rotateY: 1200
                        }
                      }
                    },
                    interactivity: {
                      detect_on: "canvas",
                      events: {
                        onhover: {
                          enable: true,
                          mode: "grab"
                        },
                        onclick: {
                          enable: true,
                          mode: "push"
                        },
                        resize: true
                      },
                      modes: {
                        grab: {
                          distance: 150,
                          line_linked: {
                            opacity: 1
                          }
                        },
                        bubble: {
                          distance: 400,
                          size: 40,
                          duration: 2,
                          opacity: 8,
                          speed: 3
                        },
                        repulse: {
                          distance: 200,
                          duration: 0.4
                        },
                        push: {
                          particles_nb: 4
                        },
                        remove: {
                          particles_nb: 2
                        }
                      }
                    },
                    retina_detect: true
                  }}
                />
              </div>

              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="container pt-lg-md">
              <div className="row justify-content-center">
                <div className="container">
                  <h1 className="display-3  text-white">
                    Cuenta:
                    <span>{authUser.email}</span>
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </main>

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Resetear password</h2>
              <PasswordForgetForm />
            </div>
            <div className="col-md-6">
              <h2>Cambiar contraseña</h2>
              <PasswordChangeForm />
            </div>
          </div>
          {/* <UpdatePhotoProfile authUser={authUser} /> */}

          <LoginManagement authUser={authUser} />
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
);

class UpdatePhotoProfileBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadValue: 0
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event, authUser) {
    const file = event.target.files[0];
    console.log(file);
    console.log(this.props.firebase);

    const storageRef = this.props.firebase.picture(file);
    const task = storageRef.put(file);
    this.setState({
      name: file.name
    });

    task.on(
      "state_changed",
      snapshot => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage
        });
      },
      error => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => {
            this.setState({
              picture: url
            });
            console.log(authUser);
            this.props.firebase.user(authUser.uid).update({
              ...authUser,
              avatar: url,
              editedAt: this.props.firebase.fieldValue.serverTimestamp()
            });
          });
        task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
        console.log(authUser);
      }
    );
  }
  render() {
    return (
      <div>
        <h4>Fotografñia de perfil</h4>
        <div>
          <progress value={this.state.uploadValue} max="100">
            {this.state.uploadValue} %
          </progress>
          <br />
          <input
            type="file"
            onChange={event => this.handleOnChange(event, this.props.authUser)}
          />
          <br />
          <img width="90" src={this.state.picture} />
        </div>
      </div>
    );
  }
}
const UpdatePhotoProfile = withFirebase(UpdatePhotoProfileBase);
class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null })
      )
      .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        Sign In Methods:
        <ul>
          {SIGN_IN_METHODS.map(signInMethod => {
            const onlyOneLeft = activeSignInMethods.length === 1;
            const isEnabled = activeSignInMethods.includes(signInMethod.id);

            return (
              <li key={signInMethod.id}>
                {signInMethod.id === "password" ? (
                  <DefaultLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onDefaultLoginLink}
                    onUnlink={this.onUnlink}
                  />
                ) : (
                  <SocialLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onSocialLoginLink}
                    onUnlink={this.onUnlink}
                  />
                )}
              </li>
            );
          })}
        </ul>
        {error && error.message}
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink
}) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button type="button" onClick={() => onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </button>
  );

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: "", passwordTwo: "" };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: "", passwordTwo: "" });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { onlyOneLeft, isEnabled, signInMethod, onUnlink } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return isEnabled ? (
      <button
        type="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
      >
        Deactivate {signInMethod.id}
      </button>
    ) : (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <div className="input-group input-group-alternative">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="ni ni-lock-circle-open" />
              </span>
            </div>
            <input
              className="form-control"
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="New Password"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-group input-group-alternative">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="ni ni-lock-circle-open" />
              </span>
            </div>
            <input
              className="form-control"
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm New Password"
            />
          </div>
        </div>

        <div className="submit">
          <button
            className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0 btn-primary"
            disabled={isInvalid}
            type="submit"
          >
            Link {signInMethod.id}
          </button>
        </div>
      </form>
    );
  }
}

const LoginManagement = withFirebase(LoginManagementBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
