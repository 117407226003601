import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import PropTypes from "prop-types";
import ChatBot from "react-simple-chatbot";
import ChatBotIcon from "../../stylesheets/assets/icons/robot.svg";

class TarifasInternet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      contratado: "",
      servicios: ""
    };
  }
  onListenFortarifasInternet = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .tarifasInternet()
      .orderBy("orden", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let tarifasInternet = [];
          snapshot.forEach(doc =>
            tarifasInternet.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            tarifasInternet: tarifasInternet.reverse(),
            loading: false
          });
        } else {
          this.setState({
            tarifasInternet: null,
            loading: false
          });
        }
      });
  };

  componentDidMount() {
    this.onListenFortarifasInternet();
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { loading, tarifasInternet } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <div className="row gutter-0 no-gutters">
          {loading && <div>Loading ...</div>}

          {tarifasInternet &&
            tarifasInternet.map((tarifaInternet, i) => (
              <div className="" key={i}>
                <b className="">{tarifaInternet.titulo}</b>
                <br />
                <b className="">{tarifaInternet.velocidad}</b>
                <br />
                <span className="">VELOCIDAD DE DESCARGA</span>
                <br />
                Descargas Ilimitadas
                <br />
                {tarifaInternet.precio} <hr />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withFirebase(TarifasInternet);
